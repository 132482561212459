/* src/styles/App.css */
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section {
  padding: 0;
  margin: 0;
}

.hero-section,
.products-section,
.about-us-section,
.testimonials-section {
  padding-top: 0;
  padding-bottom: 0;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .hero-section {
    text-align: center;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
